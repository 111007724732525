import 'devextreme/dist/css/dx.light.css';
import { ReactNode } from 'react';
import { Links } from '../../data/data';
import { Header } from '../Header/Header';
import * as C from './styles';

import { NavLink } from 'react-router-dom';
import { useMenu } from '../../hooks/useMenu';

type Props = {
    children: ReactNode
}

export const Theme = ({ children }: Props) => {
    // const { user, currentStep, setStep } = useAuth();
    const { open } = useMenu();

    return(
        <C.Container>
            <Header />

            <C.Area>
                    <C.Steps>
                        
                        <C.Sidebar open={open}>
                            <C.ContentLeft>                                
                                <C.CheckSteps>
                                {Links.map((item, index) => (
                                    <C.BoxMenu key={index}>

                                        <C.TitleSectionSidebar>{item.title}</C.TitleSectionSidebar>
                                        {item.links.map((link, i) => (
                                            <NavLink key={i} to={link.path}> 
                                                <C.LinNav>
                                                    {link.icon}&nbsp;{link.name}
                                                </C.LinNav>
                                            </NavLink>
                                        ))}
                                    </C.BoxMenu>                        
                                ))}
                                </C.CheckSteps>
                            </C.ContentLeft>
                        </C.Sidebar>
                        <C.Page>
                            {children}
                        </C.Page>
                    </C.Steps>
                </C.Area>
        </C.Container>
    );
}