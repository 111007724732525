import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 20px;
    margin-bottom: 40px;
`;

export const ContentLeft = styled.div`
    width: 25%;
    padding: 20px;
    height: 100%;
    background-color: #F0F0F0;
    border-radius: 6px;
`;

export const BoxInputDate = styled.div`
    margin:20px 0;
    color: #444;

    input[type="date"] {
        width: 100%;
        margin-top: 10px;
        color: inherit;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 1px solid #CCC;
        background-color: #F0F0F0;
        outline: 0;
    }
`;

export const ContentRight = styled.div`
    width: 100%;
    max-width: 1000px;
    padding: 20px 40px;

    @media screen and (max-width:760px){
        padding: 20px 0;
    }
`;

export const HeaderContentRight = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
        color: #777;
        font-size:12px;
    }

    @media screen and (max-width: 760px) {
        flex-direction: column;
        margin-bottom:10px;
    }
`;

export const ContentRightForm = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 0;
`;

export const BoxFields = styled.div`
    display: flex;
    flex-direction: row;
`;

export const ContentRightFormBoxLeft = styled.div`
    flex: 1;
    padding: 0 20px 0 0;

    @media screen and (max-width: 760px){
        padding: 0;
    }
`;
export const ContentRightFormBoxRight = styled.div`
    flex: 1;
    padding: 0 0 0 20px;
`;

export const BoxButtonSubmit = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 10px;

    button {
        width: 193px;
        @media screen and (max-width:760px){
            width: 100%;
            margin-bottom:0;
        }
    }

    @media screen and (max-width:760px){
        flex-direction: column;
        margin-bottom: 40px;
    }
`;

export const GroupInputs = styled.div`
    display: flex;
    justify-content: space-between;
    width:100%;
    margin:10px 0;
`;

export const SectionCards = styled.div`
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;

    @media screen and ( max-width:990px ) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and ( max-width:546px ) {
        grid-template-columns: 1fr;
    }
`;

export const SectionDoubleTable = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    width: 100%;

    @media screen and ( max-width:990px ) {
        grid-template-columns: 1fr;
    }
`;

export const TableWrapper = styled.div`
    width: 100%;
    margin-bottom: 40px;
    border-radius: 8px;

    h2 {
        color: #444;
        margin: 10px 0;
    }
`;

export const TableVistorias = styled.div`
    width: 100%;
    margin-bottom: 40px;
    border-radius: 8px;

    h2 {
        color: #444;
        margin: 10px 0;
    }
`;

export const TableContent = styled.div`
    width: 100%;
    height: 100%;
    /* box-shadow: 1px 2px 15px rgba(0,0,0, .1); */
    border-radius: 8px;
`;

export const TableBottom = styled.div`
    
    h2 {
        color: #444;
        margin: 10px 0;
    }
`;