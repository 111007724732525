import { DistribuicaoSementes, Rusticidade, YesNot } from "../data/data";

export const yesOrNot = (num: any): string => {
    if(num === undefined) return "";
    return num ? YesNot[0] : YesNot[1];
}

export const showOption = (op: any): string => {
    if(op === undefined) return "";
    const splString = op.split('-');
    return splString[1];
}

export const rusticidadeOp = (data: any) => {
    if(data === undefined) return;
    return Rusticidade[data-1];
}

export const distribSementes = (data: any) => {
    if(data === undefined) return;
    return DistribuicaoSementes[data-1];
}

function phoneMask(tel: string) {
    tel=tel.replace(/\D/g,"");
    tel=tel.replace(/^(\d{2})(\d)/g,"($1) $2");
    tel=tel.replace(/(\d)(\d{4})$/,"$1-$2");
    return tel;
}

function verificaVistoria(status: number) {
    return status === 1 ? "Vistoriado" : "Não vistoria"    
}

function verificaAdm(status: number) {
    return status === 1 ? "Sim" : "Não"    
}

export { phoneMask, verificaVistoria, verificaAdm }