import { InputHTMLAttributes } from 'react';
import * as C from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
    label: string;
    plcholder: string;
    type?: string;
    width?: string;
}

export const Input = ({ label, plcholder, type, width, ...rest}: Props) => {
    return (
        <C.ContainerInput width={width}>
            <C.LabelInput>{label}</C.LabelInput>
            <C.Input type={type} placeholder={plcholder} {...rest}/>
        </C.ContainerInput>
    )
}