import { AuthProvider } from './auth';
import { MenuProvider } from './useMenu';

const AppProvider = ({children}: any) => {
  return(
    <AuthProvider>
      <MenuProvider>
        {children}
      </MenuProvider>
    </AuthProvider>
  )
};

export default AppProvider;
