import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    height: 100vh;
    margin-bottom: 40px;
`;

export const ContentLeft = styled.div`
    width: 25%;
    padding: 20px;
    height: 100%;
    background-color: #F0F0F0;
    border-radius: 6px;
`;

export const ContentRight = styled.div`
    width: 100%;
    max-width: 1600px;
    padding: 20px 40px;

    @media screen and (max-width:760px){
        padding: 20px;
    }

   
`;

export const HeaderContentRight = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
        color: #777;
        font-size:12px;
    }

    @media screen and (max-width: 760px) {
        flex-direction: column;
        margin-bottom:10px;
    }
`;

export const ContentRightForm = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 0;
`;

export const ContentRightFormBoxLeft = styled.div`
    flex: 1;
    padding: 0 20px 0 0;

    @media screen and (max-width: 760px){
        padding: 0;
    }
`;
export const ContentRightFormBoxRight = styled.div`
    flex: 1;
    padding: 0 0 0 20px;
`;

export const BoxRegulamento = styled.div`
    padding: 20px;
    background-color: #fff;
    border-radius:4px;
    box-shadow: 2px 2px 8px rgba(0,0,0,.2);

    p {
        color: #525252;
        margin-bottom: 15px;
    }
`;

export const WrapperCheckbox = styled.div`
    display: flex;
    align-items: center;
    margin:15px 0;

    input[type="checkbox"] {
        min-width:16px;
        min-height: 16px;
        margin-right: 8px;
        cursor: pointer;
    }

    span {
        font-size: 15px;
        color: #4F4F4F;
    }
`;

export const BoxButtonSubmit = styled.div`
    display: flex;
    align-items: flex-end;
    width: 100%;
    margin-top: 10px;

    button {
        display: flex;
        justify-content:center;
        align-items: center;
        font-size: 1rem;
        color: #fff;
        padding: 8px 20px;
        margin-left: 15px;
        background-color: #27AE60;
        border: 0;
        border-radius: 4px;
        box-shadow: 1px 2px 15px rgba(0,0,0, .1);
        cursor: pointer;

        &:hover {
            opacity: .8;
        }
    }

    @media screen and (max-width:760px){
        flex-direction: column;
        margin-bottom: 40px;
    }
`;