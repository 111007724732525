import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { DataGrid, Popup } from 'devextreme-react';
import { Column, Paging, SearchPanel } from 'devextreme-react/data-grid';
// import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import api from '../../services/api';
import { phoneMask, verificaAdm } from '../../helpers/helpers';
import { Position, ToolbarItem } from 'devextreme-react/autocomplete';
import { toast } from 'react-toastify';
import { User } from '../../types/PropertyTypes';

export const Usuarios = () => {
    const [properties, setProperties] = useState([])
    const [isPopupVisible, setPopupVisibility] = useState(false);
    const [userSelect, setUserSelect] = useState<User>({} as User);
    const [coins, setCoins] = useState("");
    // const navigate = useNavigate();

    const loadData = useCallback(async () => {
        api.get('/adm/users')
            .then((res) => {
                setProperties(res.data);
            }).catch(e => console.log(e.response.data.message))
    }, []);

    function maskPhone(data: any) {
        return data.value ? phoneMask(data.value) : "";
    }

    function checkAdm(data: any) {
        return verificaAdm(data.value)
    }

    function intlFunc(data: any) {
        return Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(data.value)
    }

    const handleAddCoin = useCallback(() => {
        if(userSelect.id === undefined){
            toast.error('Selecione um usuario')
        }
        if(coins === ""){
            toast.error('Digite um valor válido!')
        }
        api.post('/adm/transaction/', {
            user_id: userSelect.id,
            type:2,
            value:coins
        }).then(res => {
            toast.success(`Coins adicionados com sucesso para ${userSelect.name}`)
            setPopupVisibility(false);
            setUserSelect({} as User);
            setCoins("");
            loadData();
        }).catch(e => {
            toast.error(e.response.data.message)
        })
    },[userSelect, coins, loadData])

    useEffect(() => {
        loadData()
    }, [loadData])

    return (
        <C.Container>
            <C.ContentRight>
                <C.HeaderContentRight>
                    <Title title='Usuários' />
                </C.HeaderContentRight>

                <C.ContentRightForm>
                    <C.BoxRegulamento>
                        <DataGrid
                            id="cadastrados"
                            dataSource={properties}
                            keyExpr="id"
                            showBorders={false}
                            showColumnLines={false}
                            columnResizingMode="nextColumn"
                            allowColumnResizing={true}
                            rowAlternationEnabled={true}
                            hoverStateEnabled={true}
                            onRowClick={(e) => {
                                setUserSelect(e.data);
                                setPopupVisibility(!isPopupVisible)
                            }}
                        >
                            <Paging defaultPageSize={20} />
                            <SearchPanel visible={true} />
                            <Column caption="Id" dataField="id" width='60' />
                            <Column caption="Nome" dataField="fullname" />
                            <Column caption="Telefone" dataField="phone" cellRender={maskPhone} />
                            <Column caption="E-mail" dataField="email" />
                            <Column caption="Coins" dataField="coins" />
                            <Column caption="Saldo" dataField="balance" cellRender={intlFunc} />
                            <Column caption="Admin" dataField="is_admin" cellRender={checkAdm} />

                        </DataGrid>
                    </C.BoxRegulamento>
                </C.ContentRightForm>
                <Popup
                    title='Adicionar coins!'
                    visible={isPopupVisible}
                    hideOnOutsideClick={true}
                    // onHiding={() => togglePopup(idVistoria)}
                    width={340}
                    height={240} 
                >
                    <Position
                        at="center"
                        my="center"
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="before"
                        render={() => (
                            <button 
                                onClick={handleAddCoin}
                                style={{ 
                                    background: "#27AE60",
                                    border: 'none',
                                    padding: '0.5rem 1rem',
                                    borderRadius:5,
                                    color: "#fff",
                                    cursor:"pointer"
                                }}
                            >Confirmar</button>
                        )}                        
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        render={() => (
                            <button 
                                onClick={() => {
                                    setPopupVisibility(false);
                                    setUserSelect({} as User);
                                    setCoins('0');
                                }}
                                style={{ 
                                    background: "#ff5252",
                                    border: 'none',
                                    padding: '0.5rem 1rem',
                                    borderRadius:5,
                                    color: "#fff",
                                    cursor:"pointer"
                                }}
                            >Cancelar</button>
                        )}
                    />
                    <p>Quantos coins você deseja adicionar para o usuário <strong>{userSelect.name}</strong></p>
                    <input
                        style={{
                            width:"100%",
                            marginTop: 16,
                            padding: "4px 8px",
                            display: 'flex',
                            alignItems: "center",
                            borderRadius: 5,
                            border: "1px solid #ddd"
                        }} 
                        value={coins} 
                        onChange={(e) => setCoins(e.target.value.replace(/\D/g, ""))}
                    />
                </Popup>
            </C.ContentRight>
        </C.Container>
    );
}