import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { DataGrid, Popup, SelectBox, TextBox } from 'devextreme-react';
import { Button, Column, Editing, Paging, SearchPanel } from 'devextreme-react/data-grid';
import { useCallback, useEffect, useState } from 'react';
import { BiPlus, BiTrash } from 'react-icons/bi';
import api from '../../services/api';
import { toast } from 'react-toastify';
import { Position, ToolbarItem } from 'devextreme-react/popup';
import { IQuestion } from '../../types/PropertyTypes';

export const Question = () => {
    const [isPopupVisible, setPopupVisibility] = useState(false);
    const [isPopupDelVisible, setIsPopupDelVisible] = useState(false);
    const [categories, setCategories] = useState<IQuestion[]>([]);

    const [question, setQuestion] = useState('');
    const [option1, setOption1] = useState('');
    const [option2, setOption2] = useState('');
    const [option3, setOption3] = useState('');
    const [correct, setCorrect] = useState('');
    const [tema, setTema] = useState('');
    const [dificuldade, setDificuldade] = useState('');
    const [act, setAct] = useState(1)

    const temas = [
        'Português',
        'Matemática',
        'Geografia',
        'História',
        'Biologia/Ciência',
        'Línguas',
        'Esportes',
        'Atualidades',
        'Musica/TV',
        'Cinema',
        'Economia',
        'Cultura',
        'Religiosas',
        'Outros'
    ];

    const dificuldades = [
        "1 - Fácil",
        "2 - Médio",
        "3 - Difícil"
    ]
    
    const [idRow, setIdRow] = useState('');
    const loadQuestion = useCallback(async () => {
        api.get('/adm/questions')
            .then((res) => {
                setCategories(res.data.data);
            })
    }, [])
    
    const createQuestion = useCallback(async () => {
        if (question === "" || option1 === "" || option2 === "" || option3 === "" || correct === "") {
            return toast.error("Atenção! preencha todos os campos.");
        }

        api.post('/adm/question', {
            question,
            option1,
            option2,
            option3,
            correct,
            tema,
            dificuldade: dificuldade.split(" ")[0],
        }).then(res => {
            if (res.status === 201) {
                toast.success("Nova pergunta criada com sucesso!");
            }
        }).catch((e) => {
            toast.error(`${e.response.data.message}`);
        }).finally(() => {
            setPopupVisibility(false);
            loadQuestion();
        })
    }, [
        question,
        option1,
        option2,
        option3,
        correct,
        tema,
        dificuldade,
        loadQuestion
    ]);

    const updateQuestion = useCallback(async () => {
        if (question === "" || option1 === "" || option2 === "" || option3 === "" || correct === "") {
            return toast.error("Atenção! preencha todos os campos.");
        }

        api.put('/adm/question', {
            id: idRow,
            question,
            option1,
            option2,
            option3,
            correct,
            tema,
            dificuldade: dificuldade.split(" ")[0],
        }).then(res => {
            if (res.status === 201) {
                toast.success("Nova pergunta criada com sucesso!");
            }
        }).catch((e) => {
            toast.error(`${e.response.data.message}`);
        }).finally(() => {
            setPopupVisibility(false);
            loadQuestion();
        })
    }, [
        loadQuestion,
        question,
        option1,
        option2,
        option3,
        correct,
        tema,
        dificuldade,
        idRow
    ]);

    const actionQuestion = useCallback((type: number) => {
        if(type === 1){ //Salvar
            createQuestion();
        }else{
            updateQuestion();
        }
    },[createQuestion, updateQuestion])

    const deleteQuestion = useCallback((idDel: string) => {
        api.delete(`/adm/question/${idDel}`)
            .then(res => {
                if (res.status === 200) {
                    toast("A categoria foi excluída")
                }
            }).catch((error) => {
                console.log(error.response.data.message);
                toast("Error ao excluir a categoria.");
            })
    },[]);


    const togglePopup = () => {
        setPopupVisibility(!isPopupVisible);
        setAct(1);
    }; //Modal Add

    const togglePopupDel = () => setIsPopupDelVisible(!isPopupDelVisible); //Modal Delete

    useEffect(() => {
        loadQuestion();
    }, [loadQuestion])

    // Func para botão editar
    // const editCat = (e: any) => {}

    // Salvar id da row e salvar no state
    // Abrir modal confirm delete
    const getDeleteCat = (e: any) => {
        setIdRow(e.row.key);
        togglePopupDel();
    }

    const getEditCat = (e: any) => {
        setAct(2)
        setIdRow(e.row.data.id);
        setQuestion(e.row.data.question)
        setOption1(e.row.data.option1)
        setOption2(e.row.data.option2)
        setOption3(e.row.data.option3)
        setCorrect(e.row.data.correct)
        setTema(e.row.data.tema)
        setDificuldade(dificuldades[e.row.data.dificuldade])
        setPopupVisibility(true);
    }

    return (
        <C.Container>
            <C.ContentRight>
                <C.HeaderContentRight>
                    <Title title='Questões' />
                </C.HeaderContentRight>

                <C.ContentRightForm>
                    <C.BoxFields>

                        <C.BoxRegulamento>
                            <C.BoxButtonSubmit>
                                <button onClick={togglePopup}>
                                    <BiPlus size={16} />
                                    Cadastrar
                                </button>
                            </C.BoxButtonSubmit>
                            <br />
                            <br />
                            <DataGrid
                                id="Questions"
                                dataSource={categories}
                                keyExpr="id"
                                showBorders={false}
                                showColumnLines={false}
                                columnResizingMode="nextColumn"
                                allowColumnResizing={true}
                                rowAlternationEnabled={true}
                            >
                                <Editing
                                    mode="row"
                                    allowUpdating={false}
                                    allowDeleting={true}
                                    allowAdding={false}
                                    useIcons={<BiTrash />}
                                    onChangesChange={e => console.log(e)}
                                />
                                <Paging defaultPageSize={20} />
                                <SearchPanel visible={true} />
                                <Column caption="Id" dataField='id' width='60' />
                                <Column caption="Pergunta" dataField='question' width='220' />
                                <Column caption="R. Correta" dataField='correct' />
                                <Column caption="R. Opt1" dataField='option1' />
                                <Column caption="R. Opt2" dataField='option2' />
                                <Column caption="R. Opt3" dataField='option3' />
                                <Column caption="Tema" dataField='tema' />
                                <Column caption='AÇÕES' width={100} alignment='center' type='buttons' >
                                    <Button name='detalhes' icon='folder' onClick={(e: any) => getEditCat(e)} />
                                    <Button name='excluir' icon='close' onClick={(e: any) => getDeleteCat(e)} />
                                </Column>
                            </DataGrid>
                        </C.BoxRegulamento>
                    </C.BoxFields>
                </C.ContentRightForm>

                <Popup
                    title='Cadastro de questões'
                    visible={isPopupVisible}
                    hideOnOutsideClick={true}
                    onHiding={togglePopup}
                    width={500}
                    height={600}
                >
                    <C.ContentModal>
                        <C.ContentModalInput>
                            <TextBox
                                stylingMode='underlined'
                                value={question}
                                labelMode='static'
                                label='Pergunta'
                                placeholder='Insira a pergunta...'
                                width="100%"
                                height={50}
                                onValueChanged={e => setQuestion(e.value)}
                            />
                            <TextBox
                                stylingMode='underlined'
                                value={option1}
                                labelMode='static'
                                label='Alternativa 1'
                                placeholder='Alternativa errada 1...'
                                width="100%"
                                height={50}
                                onValueChanged={e => setOption1(e.value)}
                            />
                            <TextBox
                                stylingMode='underlined'
                                value={option2}
                                labelMode='static'
                                label='Alternativa 2'
                                placeholder='Alternativa errada 2...'
                                width="100%"
                                height={50}
                                onValueChanged={e => setOption2(e.value)}
                            />
                            <TextBox
                                stylingMode='underlined'
                                value={option3}
                                labelMode='static'
                                label='Alternativa 3'
                                placeholder='Alternativa errada 3...'
                                width="100%"
                                height={50}
                                onValueChanged={e => setOption3(e.value)}
                            />
                            <TextBox
                                stylingMode='underlined'
                                value={correct}
                                labelMode='static'
                                label='Resposta corret'
                                placeholder='Alternativa correta...'
                                width="100%"
                                height={50}
                                onValueChanged={e => setCorrect(e.value)}
                            />
                            <SelectBox 
                                value={tema} 
                                onValueChange={e => {setTema(e)}}
                                items={temas} 
                                stylingMode='underlined' 
                                labelMode='static' 
                                label='Tema relacionado' 
                                placeholder='Selecione' 
                                width="100%" 
                                height={50}  
                            />

                            <SelectBox 
                                value={dificuldade} 
                                onValueChange={e => {setDificuldade(e)}}
                                items={dificuldades} 
                                stylingMode='underlined' 
                                labelMode='static' 
                                label='Nível de dificuldade' 
                                placeholder='Selecione' 
                                width="100%" 
                                height={50}  
                            />

                        </C.ContentModalInput>
                        <C.BoxButtonSubmitModal>
                            <button onClick={() => actionQuestion(act)}>
                                <BiPlus size={18} />
                                Salvar
                            </button>
                        </C.BoxButtonSubmitModal>
                    </C.ContentModal>
                </Popup>

                <Popup
                    title='Deseja excluir a questão?'
                    visible={isPopupDelVisible}
                    hideOnOutsideClick={true}
                    onHiding={togglePopupDel}
                    width={340}
                    height={180}
                >
                    <Position
                        at="center"
                        my="center"
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="before"
                        options={{ text: 'Exlcuir', onClick: deleteQuestion }}
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{ text: 'Cancelar', onClick: () => setIsPopupDelVisible(false) }}
                    />
                    <p>Depois que excluir não será possível desfazer esta ação!</p>
                </Popup>
            </C.ContentRight>
        </C.Container>
    );
}