import styled from 'styled-components';

type Props = {
    width?: string;
}

export const ContainerInput = styled.div<Props>`
    width: ${props => props.width};
    margin-top: 15px;
    display: inline-block;
    border-right: 10px solid #fff;
`;

export const Input = styled.input<Props>`
    width: 100%;
    padding: 0;
    height: 40px;
    font-size: 16px;
    border-top: 0;
    border-left: 0;
    border-bottom: 1px solid #CCC;
    border-right: 0;
    outline: 0;
    transition: all ease .3s;

    &:hover, &:focus {
        border-bottom: 2px solid rgba(39, 174, 96, .7);

    }
`;

export const LabelInput = styled.label`
    display:block;
    font-size: 12px;
    color: #888;
`;