import * as C from './styles';
import { Title } from '../../components/Title/Title';
import { DataGrid,  } from 'devextreme-react';
import { Column, Paging, SearchPanel } from 'devextreme-react/data-grid';
// import { useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import api from '../../services/api';
import { NBedroom } from '../../types/PropertyTypes';

export const Cups = () => {
    const [properties, setProperties] = useState<NBedroom[]>([])
    // const navigate = useNavigate();

    const loadData = useCallback(async () => {
        api.get('/adm/cups')
            .then((res) => {
                setProperties(res.data.data);
            }).catch(e => console.log(e.response.data.message))
    }, []);

    function intlFunc(data: any) {
        return Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(data.value)
    }

    useEffect(() => {
        loadData()
    }, [loadData])

    return (
        <C.Container>
            <C.ContentRight>
                <C.HeaderContentRight>
                    <Title title='Copas' />
                </C.HeaderContentRight>

                <C.ContentRightForm>
                    <C.BoxRegulamento>
                        <DataGrid
                            id="cadastrados"
                            dataSource={properties}
                            keyExpr="id"
                            showBorders={false}
                            showColumnLines={false}
                            columnResizingMode="nextColumn"
                            allowColumnResizing={true}
                            rowAlternationEnabled={true}
                            hoverStateEnabled={true}
                            onRowClick={() => {}}
                        >
                            <Paging defaultPageSize={20} />
                            <SearchPanel visible={true} />
                            <Column caption="Id" dataField="id" width='60' />
                            <Column caption="Data" dataField="created_at" dataType="date" format="dd/MM/yyyy HH:mm:ss" />
                            <Column caption="Campeão" dataField="champion.email" />
                            <Column caption="Campeão R$" dataField="champion_value" cellRender={intlFunc} />
                            <Column caption="Vice" dataField="second.email" />
                            <Column caption="Vice R$" dataField="second_value" cellRender={intlFunc} />

                        </DataGrid>
                    </C.BoxRegulamento>
                </C.ContentRightForm>
            </C.ContentRight>
        </C.Container>
    );
}