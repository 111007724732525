import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    min-height: 300px;
    color: #444;
    font-size: 1.5rem;
    font-weight: normal;
    justify-content: center;
    align-items: center;
`;