import { createContext, useCallback, useState, useContext, } from 'react';

interface Menu {
    open: boolean;
    setMenu: () => void;
}

const MenuContext = createContext<Menu>({} as Menu);

const MenuProvider = ({ children }: any) => {

    const [open, setOpen] = useState(false);
    console.log(open);

    const setMenu = useCallback(() => {
        setOpen(!open)
      },[open])

  return (
    <MenuContext.Provider value={{open, setMenu}}
    >
      {children}
    </MenuContext.Provider>
  );
};

function useMenu(): Menu {
  const context = useContext(MenuContext);

  if (!context) {
    throw new Error('useMenu must be used within an MenuProvider');
  }

  return context;
}

export { MenuProvider, useMenu };