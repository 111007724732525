import React from "react";

function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="79"
      height="44"
      fill="none"
      viewBox="0 0 79 44"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M27.273 38.577L39.54 44l-2.13-13.242-1.758 1.355 1.665 9.03-8.31-3.904-1.734 1.338z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M32.23 39.45a22.082 22.082 0 01-10.707 2.74C9.636 42.19 0 32.92 0 21.488 0 10.055 9.636.788 21.523.788s21.523 9.267 21.523 20.7c0 5.622-2.33 10.72-6.111 14.45l-.473-2.564c2.778-3.227 4.448-7.369 4.448-11.886 0-10.298-8.68-18.647-19.387-18.647S2.136 11.19 2.136 21.488c0 10.3 8.68 18.648 19.387 18.648 2.98 0 5.804-.647 8.327-1.803l2.38 1.117z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M40.16 32.751a21.799 21.799 0 001.78 2.52l.67-2.543a19.727 19.727 0 01-1.239-2.016 20.49 20.49 0 01-1.212 2.04zm-.151-22.745c.455.655.872 1.336 1.248 2.04 2.579-4.895 7.194-8.565 12.924-9.615C64.311.574 74.088 7.606 76.02 18.138c1.93 10.531-4.716 20.574-14.846 22.431-4.443.815-8.818-.08-12.493-2.231l-2.437.927c4.351 3.047 9.786 4.419 15.315 3.405 11.245-2.061 18.624-13.21 16.481-24.902C75.896 6.076 65.042-1.732 53.796.33c-5.965 1.094-10.842 4.744-13.787 9.676z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M47.524 37.604l-5.284 3.675.004-7.83-1.587-.938.242 11.45 8.177-5.398a18.942 18.942 0 01-1.552-.96z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#FF9C00"
        d="M24.968 20.776l-5.639-1.627a1.526 1.526 0 01-1.107-1.458c0-.84.69-1.52 1.54-1.52h3.462c.637 0 1.263.191 1.785.542a.822.822 0 001.018-.103l1.817-1.752c.371-.355.319-.947-.094-1.261a7.448 7.448 0 00-4.516-1.545V9.579a.832.832 0 00-.835-.824h-1.67c-.46 0-.836.371-.836.824v2.473h-.13c-3.326 0-6 2.817-5.692 6.16.22 2.374 2.058 4.305 4.376 4.975l5.351 1.545c.653.19 1.107.788 1.107 1.457 0 .84-.69 1.52-1.54 1.52h-3.462c-.637 0-1.263-.191-1.785-.541a.822.822 0 00-1.018.103l-1.817 1.75c-.37.356-.319.948.094 1.263a7.448 7.448 0 004.516 1.545V34.3c0 .453.376.824.835.824h1.67c.46 0 .836-.37.836-.824v-2.483c2.433-.046 4.715-1.473 5.519-3.744 1.122-3.172-.762-6.427-3.785-7.298z"
      ></path>
      <path
        fill="#EA2727"
        d="M68.96 12.81c-2.858-2.424-7.108-1.988-9.73.705l-1.028 1.054-1.027-1.054c-2.618-2.693-6.872-3.129-9.73-.706-3.275 2.782-3.447 7.774-.516 10.789l10.09 10.368c.651.67 1.71.67 2.362 0l10.09-10.368c2.935-3.015 2.763-8.007-.511-10.788z"
      ></path>
    </svg>
  );
}

export default Logo;
