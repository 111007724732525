import styled from 'styled-components';

export const CardsMetrica = styled.div`
    display: flex;
    flex-direction: column;
    color: #444;
    width: 100%;
    height: 120px;
    padding: 8px;
    border-radius: 8px;
    /* border-left: 6px solid rgba(37, 77, 107, .8); */
    background-color: rgba(255,255,255, 1);
    box-shadow: 1px 2px 15px rgba(0, 0, 0, .1);

    h3 {
        margin: 0;
        font-weight: 400;
    }
`;

export const CardMetricaValue = styled.div`
    display: flex;
    justify-content: center;
    align-items:center;
    height: 100%;

    span {
        font-size: 2rem;
    }
`;

export const CardMetricaIcon = styled.div`
    font-size: 1.8rem;
    margin: 8px 8px 0 0;
`;