import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from '../../components/Button/Button';
import { Input } from '../../components/input/Input';
import { useAuth } from '../../hooks/auth';
import * as C from './styles';

export const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { signIn, user } = useAuth();
    const navigate = useNavigate();

    const handleLogin = useCallback(() => {
        if(email === "" || password === ""){
            toast.error("Preencha todos os campos!")
        }
        signIn({email, password})
    },[email, password, signIn]);


    useEffect(() => {
        if (user) {
            navigate("/painel")
          }
    },[user, navigate])

    return (
        <>
            {/* <Header /> */}
            <C.Container>
                <C.ContentTitle>
                    <h2>Bem vindo ao <br /> painel do Equiz Premiado</h2>
                </C.ContentTitle>
                <C.FormLogin>
                    <C.ContentFormWrapper>
                        <Input 
                            label='Usuário' 
                            plcholder='nome@dominio.com.br'
                            onChange={(e)=>setEmail(e.target.value)} 
                            value={email}
                        />
                        <Input 
                            type="password" 
                            label='Senha' 
                            plcholder='************' 
                            onChange={(e)=>setPassword(e.target.value)} 
                            value={password}
                        />
                        
                        <C.WrapperCheckbox>
                            <input type="checkbox" />
                            <span>Manter-me conectado</span>
                        </C.WrapperCheckbox>

                        <Button onClick={handleLogin} title='ACESSAR' color='#27AE60' />

                    </C.ContentFormWrapper>
                </C.FormLogin>
            </C.Container>
        </>
    );
}