import { createContext, useCallback, useState, useContext } from 'react';
import { toast } from 'react-toastify';
import api from '../services/api';

interface User {
	id: number;
  is_admin: number;
  email: string;
  nome: string;
  nascimento: Date;
  genero: string;
  phone1: string;
  phone2: null,
  cep: string;
  logradouro: string;
  numero: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
  confirm_password?: string
}

interface AuthContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  loading: boolean;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider = ({ children }: any) => {
  const [loading, setLoading] = useState(false);  

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@QUIZadm:token');
    const user = localStorage.getItem('@QUIZadm:user');

    if (token && user ) {
      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      return { token, user: JSON.parse(user)};
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }: SignInCredentials) => {
    setLoading(true);
    try {
      const response = await api.post('/sessions', {
        email,
        password,
      });

      const { token, user } = response.data;

      if(user.is_admin !== 1){
        toast.error("Este usuario não tem permissão para acessar este site!");
        setLoading(false);
        return 
      }

      localStorage.setItem('@QUIZadm:token', token);
      localStorage.setItem('@QUIZadm:user', JSON.stringify(user));
    

      api.defaults.headers.common["Authorization"] = `Bearer ${token}`;

      setData({ token, user});
      setLoading(false);
    } catch (e) {
        toast.error("E-mail/senha não conferem!")
        setLoading(false);
    }

  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@QUIZadm:token');
    localStorage.removeItem('@QUIZadm:user');

    setData({} as AuthState);
  }, []);

  // const verifyToken = useCallback(async() => {
  //   api.get("/users/me").then(res => {
  //     if (res.status !== 200){
  //       signOut();
  //     }
  //   }).catch(() => signOut())
  // },[signOut])

  // useEffect(() => {
  //     if(data.user){
  //       verifyToken();
  //     }
  // },[data.user, verifyToken]);

  return (
    <AuthContext.Provider value={{ 
        user: data.user, 
        signIn, 
        signOut, 
        loading
    }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };