import styled from 'styled-components';
import bgLogin from '../../assets/png/paralax.png';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    background-color: rgba(0,0,0 , 0.4);
    justify-content: flex-start;
    align-items: center;
    min-height: calc(100vh);
    padding-bottom: 45px;
    background-image: url(${bgLogin}) ;
    background-repeat: no-repeat;
    background-position: botton;
    background-size: cover;
    padding: 45px 0;
    h2 {
        font-size: 2rem;
        text-align: center;
        color: #fff;
    }
    // background-attachment: fixed;
`;

export const ContentTitle = styled.div`
    
    width: 493px;
    margin-bottom: 32px;
    
`;

export const FormLogin = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 493px;
    min-height: 416px;
    background-color: #FFF;
    border-radius: 8px;
    box-shadow: 1px 2px 10px rgba(0,0,0,.4);
`;

export const ContentFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 320px;
`;

export const WrapperCheckbox = styled.div`
    display: flex;
    align-items: center;
    margin:15px 0;

    input[type="checkbox"] {
        min-width:16px;
        min-height: 16px;
        margin-right: 8px;
    }

    span {
        font-size: 15px;
        color: #4F4F4F;
    }
`;

export const ForgotPasswd = styled.span`
    font-size: 15px;
    text-align: center;
    margin:10px;
    
    a {
        color: #4F4F4F;
    }
`;