import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";

import { Painel } from "../pages/Painel/Painel";
import { Login } from "../pages/Login/Login";
import { NotFound } from "../pages/NotFound";
import { useAuth } from "../hooks/auth";
import { Theme } from "../components/Theme/Theme";
import { FormProvider } from "../contexts/FormContext";
import { Usuarios } from "../pages/Usuarios/Usuarios";
import { Question } from "../pages/Question/Question";
import { Transactions } from "../pages/Transactions";
import { Cups } from "../pages/Cups";

export const RoutesApp: React.FC = () => {
    const { user } = useAuth();

    function Auth({ children }: { children: JSX.Element }) {
        let location = useLocation();

        if (!user) {
            return <Navigate to="/" state={{ from: location }} replace />;
        }
        return children;
    }

    return (
        <BrowserRouter>
            <FormProvider>
                <Routes>
                    <Route path="/" element={<Login />} />                    
                    <Route path="/painel" element={<Auth><Theme><Painel /></Theme></Auth>} />
                    <Route path="/usuarios" element={<Auth><Theme><Usuarios /></Theme></Auth>} />
                    <Route path="/financeiro" element={<Auth><Theme><Transactions /></Theme></Auth>} />
                    <Route path="/perguntas" element={<Auth><Theme><Question /></Theme></Auth>} />
                    <Route path="/copas" element={<Auth><Theme><Cups /></Theme></Auth>} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </FormProvider>
        </BrowserRouter>
    )
}
