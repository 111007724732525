import * as C from './styles';
import 'devextreme/dist/css/dx.light.css';
import { CardInfo } from '../../components/CardInfo';
import { DataGrid, Popup } from 'devextreme-react';
import { BiCoin, BiMoney, BiUser } from 'react-icons/bi';
import { Title } from '../../components/Title/Title';
import { Column } from 'devextreme-react/data-grid';
import { useCallback, useEffect, useState } from 'react';
import api from '../../services/api';
import { TablePanel, TDataCards, User } from '../../types/PropertyTypes';
import { Position, ToolbarItem } from 'devextreme-react/autocomplete';


export const Painel = () => {
    const [infoCards, setInfoCards] = useState<TDataCards>({} as TDataCards);
    const [tables, setTables] = useState<TablePanel>({} as TablePanel);
    const [isPopupVisible, setPopupVisibility] = useState(false);
    const [userSelect, setUserSelect] = useState<User>({} as User);
    const [transId, setTransId] = useState('');

    const loadCards = useCallback(async () => {
        api.get('/adm/painel')
            .then((res) => {
                setInfoCards(res.data);
            })
    }, [])

    const loadTables = useCallback(async () => {
        api.get('/adm/painel/tables')
            .then((res) => {
                console.log(res.data)
                setTables(res.data);
            })
    }, [])

    useEffect(() => {
        loadCards();
        loadTables();
    }, [loadCards, loadTables]);

    function intlFunc(data: any) {
        return Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(data.value)
    }

    const updateTransaction = useCallback(async() => {
        api.put('/transactions', {
            transaction_id: transId
        })
            .then((res) => {
                loadTables();
            }).finally(() => {
                setPopupVisibility(false);
                setTransId('')
            })
    },[transId, loadTables])


    return (
        <C.Container>
            <C.HeaderContentRight>
                <Title title='Painel' />
            </C.HeaderContentRight>

            <C.SectionCards>
                <CardInfo title='Usuários cadastrados' value={infoCards.users} icon={<BiUser />} />
                <CardInfo title='Copas realizadas' value={infoCards.cups} icon={<BiCoin />} />
                <CardInfo 
                    title='Entradas em R$' 
                    value={Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                    }).format(infoCards.in)} 
                    icon={<BiMoney />} 
                />
                <CardInfo 
                    title='Saídas em R$' 
                    value={Intl.NumberFormat('pt-BR', {
                        style: 'currency',
                        currency: 'BRL'
                    }).format(infoCards.out)} 
                    icon={<BiMoney />} 
                />
            </C.SectionCards>

            <C.SectionDoubleTable>
                <C.TableWrapper>
                    <h2>Últimos cadastrados</h2>
                    <C.TableContent>
                        <DataGrid
                            id="cadastrados"
                            keyExpr="id"
                            dataSource={tables.users}
                            showBorders={false}
                            showColumnLines={false}
                            columnResizingMode="nextColumn"
                            allowColumnResizing={true}
                            rowAlternationEnabled={true}
                            hoverStateEnabled={true}
                        >
                            <Column caption="Id" dataField="id" width='60' />
                            <Column caption="Nome" dataField="fullname" />
                            <Column caption="E-mail" dataField="email" />
                            <Column caption="Telefone" dataField="phone" />
                            <Column caption="Data Cad." dataField="created_at" dataType="date" format="dd/MM/yyyy" />
                        </DataGrid>
                    </C.TableContent>
                </C.TableWrapper>
                <C.TableWrapper>
                    <h2>Últimas copas</h2>
                    <C.TableContent>
                        <DataGrid
                            id="vistorias"
                            keyExpr="id"
                            dataSource={tables.cups}
                            showBorders={false}
                            showColumnLines={false}
                            columnResizingMode="nextColumn"
                            allowColumnResizing={true}
                            rowAlternationEnabled={true}
                            hoverStateEnabled={true}
                        >
                            <Column caption="Id" dataField='id' width='60' />
                            <Column caption="Data copa" dataField='created_at' dataType="date" format="dd/MM/yyyy" width='110' />
                            <Column caption="Campeão" dataField='champion.name' />
                            <Column caption="Valor Camp." dataField='champion_value' width='100' cellRender={intlFunc}/>
                            <Column caption="Vice" dataField='second.name' />
                            <Column caption="Valor Vice" dataField='second_value' width='100' cellRender={intlFunc}/>
                        </DataGrid>
                    </C.TableContent>
                </C.TableWrapper>
            </C.SectionDoubleTable>

            <C.SectionDoubleTable>
                <C.TableBottom>
                    <h2>Últimos depósitos</h2>
                    <DataGrid
                        id="ultimos-pagamentos"
                        keyExpr="id"
                        dataSource={tables.in}
                        showBorders={false}
                        showColumnLines={false}
                        columnResizingMode="nextColumn"
                        allowColumnResizing={true}
                        rowAlternationEnabled={true}
                        hoverStateEnabled={true}
                    >
                        <Column dataField='id' width='60' />
                        <Column dataField='created_at' caption="Data" dataType="date" format="dd/MM/yyyy HH:mm:ss" />
                        <Column dataField='user.name' caption="Nome" />
                        <Column dataField='user.email' caption="E-mail" />
                        <Column dataField='user.phone' caption="Telefone" />
                        <Column dataField='value' caption="Valor" cellRender={intlFunc}/>
                        <Column dataField='status' caption="Status" />
                    </DataGrid>
                </C.TableBottom>
                <C.TableWrapper>
                    <h2>Últimos solicitações pagamentos</h2>
                    <DataGrid
                        id="cupons"
                        keyExpr="id"
                        dataSource={tables.out}
                        showBorders={false}
                        showColumnLines={false}
                        columnResizingMode="nextColumn"
                        allowColumnResizing={true}
                        rowAlternationEnabled={true}
                        hoverStateEnabled={true}
                        onRowClick={(e) => {
                            setUserSelect(e.data.user);
                            setTransId(e.data.id)
                            setPopupVisibility(!isPopupVisible)
                        }}
                    >
                        <Column dataField='id' width='60' />
                        <Column dataField='created_at' caption="Data" dataType="date" format="dd/MM/yyyy HH:mm:ss"/>
                        <Column dataField='user.name' caption="Nome" />
                        <Column dataField='user.email' caption="E-mail" />
                        <Column dataField='user.phone' caption="Telefone" />
                        <Column dataField='value' caption="Valor" cellRender={intlFunc}/>
                        <Column dataField='status' caption="Status" />
                    </DataGrid>
                </C.TableWrapper>
            </C.SectionDoubleTable>
            <Popup
                    title='Confirmar pagamento!'
                    visible={isPopupVisible}
                    hideOnOutsideClick={true}
                    // onHiding={() => togglePopup(idVistoria)}
                    width={400}
                    height={280} 
                >
                    <Position
                        at="center"
                        my="center"
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="before"
                        render={() => (
                            <button 
                                onClick={updateTransaction}
                                style={{ 
                                    background: "#27AE60",
                                    border: 'none',
                                    padding: '0.5rem 1rem',
                                    borderRadius:5,
                                    color: "#fff",
                                    cursor:"pointer"
                                }}
                            >Confirmar</button>
                        )}                        
                    />
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        render={() => (
                            <button 
                                onClick={() => {
                                    setPopupVisibility(false);
                                    setUserSelect({} as User);
                                }}
                                style={{ 
                                    background: "#ff5252",
                                    border: 'none',
                                    padding: '0.5rem 1rem',
                                    borderRadius:5,
                                    color: "#fff",
                                    cursor:"pointer"
                                }}
                            >Cancelar</button>
                        )}
                    />
                    <p>Deseja confirmar o pagamento para o usuário <strong>{userSelect.name}</strong></p>
                    <br/>
                    <p><strong>Nome: </strong>{userSelect.fullname}</p>
                    <p><strong>CPF: </strong>{userSelect.document}</p>
                    <p><strong>Tipo de chave: </strong>{(userSelect.type_pix_key === "phone" && "Telefone")||(userSelect.type_pix_key === "doc" && "CPF/CNPF")||(userSelect.type_pix_key === "mail" && "E-mail")||(userSelect.type_pix_key === "key" && "Chave aleatória")}</p>
                    <p><strong>Chave Pix: </strong>{userSelect.pix_key}</p>
                </Popup>
        </C.Container>
    );
}