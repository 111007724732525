import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
// import { Theme } from './components/Theme/Theme';
import { RoutesApp } from './routes/MainRoutes';
// import { FormProvider } from './contexts/FormContext';
import AppProvider from './hooks';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <AppProvider>
      <ToastContainer />
      <RoutesApp />
    </AppProvider>
  );
}

export default App;